import { Register } from '@/di';

export const CONFIG_SERVICE_ID = Symbol('configService');

@Register(CONFIG_SERVICE_ID)
export default class ConfigService {
  FuelServiceHost = process.env.VUE_APP_API_FUEL_HOST;

  FuelUtilityServiceHost = process.env.VUE_APP_API_FUEL_UTILITY_HOST;

  FuelTariffPolicyServiceHost = process.env.VUE_APP_API_FUEL_TARIFF_POLICY_HOST;

  FuelReportHost = process.env.VUE_APP_API_FUEL_REPORT_HOST;

  CommonUserHost = process.env.VUE_APP_API_COMMONS_USER_HOST;

  CarrierStatisticHost = process.env.VUE_APP_API_CARRIER_STATISTIC_HOST;

  SRMServiceHost = process.env.VUE_APP_API_SRM_HOST;

  IMServiceHost = process.env.VUE_APP_API_IM_HOST;

  SRMWarrantyReportsRemoteService = process.env.VUE_APP_API_WARRANTY_REPORTS;

  SRMOrderHost = process.env.VUE_APP_API_ORDER_SRM_HOST;

  SRMOrderHostV2 = process.env.VUE_APP_API_ORDER_SRM_HOST_V2;

  SRMUserHost = process.env.VUE_APP_API_SRM_USERS_HOST;

  SRMHubServiceHost = process.env.VUE_APP_HUB_SRM_HOST;

  CRMClientService = process.env.VUE_APP_API_CRM_CLIENTS_HOST;

  SRMReportServiceHost = process.env.VUE_APP_API_REPORTS_SRM_HOST;

  SRMCounterpartyServiceHost = process.env.VUE_APP_API_COUNTERPARTY_SRM_HOST;

  SRMTelegramNotificationsBot = process.env.VUE_APP_SRM_TELEGRAM_NOTIFICATIONS_BOT;

  SRMIcqNotificationsBot = process.env.VUE_APP_SRM_ICQ_NOTIFICATIONS_BOT;

  SRMContractsServiceHost = process.env.VUE_APP_API_SRM_CONTRACTS;

  SRMDictionaryServiceHost = process.env.VUE_APP_API_SRM_DICTIONARY_HOST;

  SRMDictionaryServiceHostV2 = process.env.VUE_APP_API_SRM_DICTIONARY_HOST_V2;

  SRMLoyaltyServiceHost = process.env.VUE_APP_API_LOYALTY_HOST;

  AverageRateServiceHost = process.env.VUE_APP_API_AVERAGE_RATE_HOST;

  CarrierPreorderServiceHost = process.env.VUE_APP_API_CARRIER_PREORDER_HOST;

  SRMApiComposerServiceHost = process.env.VUE_APP_API_SRM_API_COMPOSER_HOST;

  TTServiceHost = process.env.VUE_APP_API_TASK_MAMANGER_HOST;

  IntegrationsAtisuConnectorServiceHost = process.env.VUE_APP_INTEGRATIONS_ATISU_CONNECTOR_HOST;

  /** CRM */
  CRMDictionaryServiceHost = process.env.VUE_APP_API_CRM_DICTIONARY_HOST;

  CRMRequestsServiceHost = process.env.VUE_APP_API_CRM_REQUESTS_HOST;

  CRMLocationsServiceHost = process.env.VUE_APP_API_CRM_LOCATIONS_HOST;

  CRMSearchServiceHost = process.env.VUE_APP_API_CRM_SEARCH_HOST;

  AdditionalFieldsServiceHost = process.env.VUE_APP_API_ADDITIONAL_SERVICE_HOST;

  CRMRequestDocumentsHost = process.env.VUE_APP_API_CRM_REQUEST_DOCUMENTS_HOST;

  CRMNotificationsServiceHost = process.env.VUE_APP_API_CRM_NOTIFICATIONS_HOST;

  SRMWarrantyServiceHost = process.env.VUE_APP_API_WARRANTY;

  SRMWebsocketGatewayServiceHost = process.env.VUE_APP_API_WEBSOCKET_GATEWAY;

  SRMWebsocketGatewayServiceHostV2 = process.env.VUE_APP_API_WEBSOCKET_GATEWAY_V2_SRM;

  SRMWebsocketOrderAuctionSubscribeDestination = process.env.VUE_APP_API_WEBSOCKET_ORDER_AUCTION_SUBSCRIBE_DESTINATION;

  SRMWebsocketOrderAuctionSendDestination = process.env.VUE_APP_API_WEBSOCKET_ORDER_AUCTION_SEND_DESTINATION;

  SRMWebsocketOrderRequestSubscribeDestination = process.env.VUE_APP_API_WEBSOCKET_ORDER_REQUEST_SUBSCRIBE_DESTINATION;

  SRMWebsocketOrderRequestSendDestination = process.env.VUE_APP_API_WEBSOCKET_ORDER_REQUEST_SEND_DESTINATION;

  SRMOrderAuctionServiceHost = process.env.VUE_APP_API_ORDER_AUCTION;

  SBServiceHost = process.env.VUE_APP_API_SECURITY_HOST;

  SOPServiceHost = process.env.VUE_APP_API_SOP_HOST;

  SOPServiceHostV2 = process.env.VUE_APP_API_SOP_HOST_V2;

  printServiceHost = process.env.VUE_APP_API_PRINT_HOST;

  suggestionsServiceHost = process.env.VUE_APP_API_SUGGESTIONS_HOST;

  suggestionsServiceKey = process.env.VUE_APP_API_SUGGESTIONS_KEY;

  atisuServiceHost = process.env.VUE_APP_API_ATISU_HOST;

  recognitionServiceHost = process.env.VUE_APP_API_RECOGNITION_HOST;

  svcEcomService = process.env.VUE_APP_SVC_ECOM_HOST;

  recognitionAnalyticsServiceHost = process.env.VUE_APP_API_RECOGNITION_ANALYTICS_HOST;

  sentryDSN = process.env.VUE_APP_SENTRY_DSN;

  sentryEnvironment = process.env.VUE_APP_SENTRY_ENVIRONMENT;

  sentryEnabled = process.env.VUE_APP_SENTRY_ENABLED === 'true';

  keycloakHost = process.env.VUE_APP_KEYCLOAK_HOST;

  keycloakRealm = process.env.VUE_APP_KEYCLOAK_REALM;

  keycloakClientId = process.env.VUE_APP_KEYCLOAK_CLIENT_ID;

  keycloakRefreshRate = 60000;

  yandexMetrikaId = process.env.VUE_APP_YANDEX_METRIKA_ID;

  yandexMapKey = process.env.VUE_APP_YANDEX_MAP_KEY;

  googleTagId = process.env.VUE_APP_GTAG_ID;

  keycloakGatewayService = process.env.VUE_APP_API_KEYCLOAK_GATEWAY_SERVICE_HOST;

  isNewStatusModel = process.env.VUE_APP_CRM_IS_NEW_STATUS_MODEL;

  spreadsheetId = process.env.VUE_APP_SPREADSHEET_ID;

  managersSpreadsheetId = process.env.VUE_APP_MANAGERS_SPREADSHEET_ID;

  googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;

  talkMeScriptId = process.env.VUE_APP_TALK_ME_SCRIPT_ID;

  /** MRZ */
  MRZServiceHost = process.env.VUE_APP_API_MRZ_HOST;

  MRZDispatcherServiceHost = process.env.VUE_APP_API_MRZ_DISPATCHER_HOST;

  MrzSvcMapperServiceHost = process.env.VUE_APP_API_MRZ_SVC_MAPPER;

  MrzSvcMapperServiceHostV2 = process.env.VUE_APP_API_MRZ_SVC_MAPPER_V2;

  technicalSupportHost = process.env.VUE_APP_API_TECHNICAL_SUPPORT;

  carriersCommunicationServiceHost = process.env.VUE_APP_API_CARRIERS_COMMUNICATION_HOST;

  carriersOrderHost = process.env.VUE_APP_API_ORDERS_CARRIER_HOST;

  // Behaviour settings
  fileUploadTimeout = 90000;

  recognitionTimeout = 20000;

  requestTimeout = Number(process.env.VUE_APP_REQUEST_TIMEOUT);

  // Roles
  expeditionManagerRole = 'SRM_ManagerExpedite';

  dispatcherRole = 'SRM_Dispatcher';

  expeditionManagerInitialRoute = 'orders';

  expeditionRoleId = '1e8f63cf-3535-4a37-9367-03e885ebcd16';

  attractionManagerRoleId = '917592d7-e8e1-4868-a6ee-61f9ff59c943';

  attractionManagerRole = 'SRM_ManagerAttract';

  attractionManagerInitialRoute = 'orgs';

  clientManagerRole = 'CRM_ClientManager';

  clientManagerInitialRoute = 'requests';

  securityRole = 'SB_User';

  securityInitialRoute = 'security-requests';

  sopClientManagerRole = 'SOP_ClientManager';

  sopChiefClientManagerRole = 'SOP_ChiefClientManager';

  sopClientManagerInitialRoute = 'contract-clients';

  sopMepRole = 'SOP_ManagerExpedite';

  sopChiefMepRole = 'SOP_ManagerExpediteChief';

  sopMepInitialRoute = 'mep-plan';

  sopLogistRole = 'SOP_Logist';

  sopChiefLogistRole = 'SOP_ChiefLogist';

  sopLogistInitialRoute = 'logist-plan';

  sopChiefRole = 'SOP_Chief';

  sopChiefInitialRoute = 'report';

  fuelInitialRoute = 'fuel';

  fuelAdmin = 'Fuel_admin';

  fuelClient = 'Fuel_client';

  carrierEmployee = 'SRM_CarrierEmployee';

  srmChiefRole = 'SRM_Chief';

  srmAdminRole = 'SRM_Admin';

  tariffAdmin = 'Tariff_Administrator';

  sdNotifier = 'SD_Notifier';

  srmLogisticRole = 'SRM_Logist';

  // Storage keys
  srmApiAuthTokenKey = 'SRM_API_AUTH_TOKEN';

  environment = process.env.VUE_APP_SENTRY_ENVIRONMENT;

  version = process.env.VUE_APP_VERSION;

  FuelInstructionLink = 'https://drive.google.com/file/d/1uAs6kx36bJhp6_3h8biJ4dzInK8Xo4vO';

  CarrierInstructionLink = 'https://docs.google.com/document/d/1-QJXO33-SDnJzjIkChpyI4ve7YRu-dtf9yM0KE1RgMQ';

  securityDashboardLink = 'https://grafana.gt.local/d/_sh0DMU7k/sb-monitor?orgId=1';
}
