import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { Event } from '@sentry/browser';
import { App } from 'vue';
import container from '@/di';
import ConfigService, { CONFIG_SERVICE_ID } from '@/features/shared/services/config';

export function createLogger(
  vueInstance: App,
  configService = container.get<ConfigService>(CONFIG_SERVICE_ID),
): typeof Sentry {
  Sentry.init({
    Vue: vueInstance,
    release: `gt-portal@${configService.version}`,
    enabled: configService.sentryEnabled,
    dsn: configService.sentryDSN,
    environment: configService.sentryEnvironment,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'top.GLOBALS',
      'Non-Error promise rejection captured with value: true',
    ],
    denyUrls: [
      /mc\.yandex\.ru/i,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /extensions\//i,
      /hub\//i,
      /^chrome:\/\//i,
    ],

    beforeSend(event: Event) {
      if (!event.breadcrumbs) return event;

      const filteredBreadcrumbs = event.breadcrumbs
        .filter((value) => !(value.category === 'xhr' && value.data?.url.search(/mc\.yandex\.ru/i) >= 0));

      return { ...event, breadcrumbs: filteredBreadcrumbs };
    },
  });

  return Sentry;
}

export const LOGGER_ID = Symbol('logger');
